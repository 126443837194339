<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="colorSnak"
    >
      {{ text }}
      <v-btn
        color="black"
        text
        @click="snackbar = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
    <base-material-card
      color="indigo"
      icon="mdi-account-group"
      title="Listado de clientes"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="clients"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >
            <v-card>
              <v-card-title>
                <span
                  class="headline"
                >
                  {{ formTitle }}
                </span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.id"
                        label="DNI"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.apellidos"
                        label="Apellidos"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.nombre"
                        label="Nombres"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.correo"
                        label="Correo"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="3"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.tel"
                        label="Teléfono"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="3"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.ciudad"
                        label="Ciudad"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            color="error"
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
          <v-icon
            small
            color="info"
            @click="printer(item)"
          >
            mdi-printer
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn
            color="primary"
            @click="initialize"
          >
            Reset
          </v-btn>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
  import axios from 'axios'
  export default {
    name: 'ClientesClintes',

    data () {
      return {
        dialog: false,
        snackbar: false,
        colorSnak: 'info',
        timeout: 3000,
        text: '',
        headers: [
          {
            text: 'DNI/NIE/PASSPORT',
            align: 'left',
            sortable: false,
            value: 'dni',
          },
          {
            text: 'Apellidos',
            value: 'apellidos',
          },
          {
            text: 'Nombre',
            value: 'nombre',
          },
          {
            text: 'Fecha',
            value: 'fecha',
          },
          {
            text: 'Correo',
            value: 'correo',
          },
          {
            text: 'Teléfono',
            value: 'telefono',
          },
          {
            text: 'Ciudad',
            value: 'ciudad',
          },
          {
            text: 'Acciones',
            value: 'actions',
            sortable: false,
          },
        ],
        clients: [],
        editedIndex: -1,
        editedItem: {
          id: '',
          nombre: '',
          apellidos: '',
          correo: 0,
          tel: 0,
          ciudad: 0,
        },
        defaultItem: {
          id: '',
          nombre: '',
          apellidos: '',
          correo: 0,
          tel: 0,
          ciudad: 0,
        },
      }
    },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      fetchClients () {
        axios.get('/clientes')
          .then(response => {
            this.clients = response.data
          })
      },
      initialize () {
        this.fetchClients()
      },

      editItem (item) {
        this.editedIndex = this.clients.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        const index = this.clients.indexOf(item)
        if (confirm('Realmente desea de eliminar este cliente?')) {
          if (confirm('Confirme nuevamente que desea eliminar el cliente')) {
            axios.delete('/clientes/' + item.id)
              .then(response => {
                console.log(response)
                this.text = 'Cliente eliminado correctamente'
                this.colorSnak = 'success'
                this.snackbar = true
                this.clients.splice(index, 1)
              })
              .catch(error => {
                if (error.response) {
                  this.text = 'Lo sentimos, el cliente no puede ser eliminado en este momento'
                  this.colorSnak = 'error'
                  this.snackbar = true
                } else if (error.request) {
                  console.log(error.request)
                } else {
                  console.log('Error', error.message)
                }

                console.log(error.config)
              })
          }
        }
      },
      forceFileDownload (response, nombre) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', nombre)
        document.body.appendChild(link)
        link.click()
      },
      async printer (item) {
        await axios({
          method: 'get',
          url: `/clientes/download/pdf/${item.id}`,
          responseType: 'blob',
        })
          .then(response => {
            console.log(response)
            this.forceFileDownload(response, item.dni + '_report.pdf')
          })
          .catch(error => {
            if (error.response) {
              this.text = 'Lo sentimos, el archivo no puede ser descargado en este momento'
              this.colorSnak = 'error'
              this.snackbar = true
            } else if (error.request) {
              console.log(error.request)
            } else {
              console.log('Error', error.message)
            }

            console.log(error.config)
          })
      },
      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.clients[this.editedIndex], this.editedItem)
        } else {
          this.clients.push(this.editedItem)
        }
        this.close()
      },
    },
  }
</script>
